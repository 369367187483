// libs
import { useState, useEffect } from "react";

// tools
import API from '../../api/api';

// styles
import classes from "./styles.module.css";

export default function Footer() {
  const [views, setViews] = useState(0);

  useEffect(() => {
    API.visit()
    .then(d => {
      if (d?.visits) {
        setViews(d.visits);
      }
    });
  }, []);

  return (
    <div className={classes.footer}>
      <p className={classes.footerText}>
        Coded in{" "}
        <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">
          Visual Studio Code.
        </a>{" "}
        Built with{" "}
        <a href="https://react.dev/" target="_blank" rel="noreferrer">
          React.js.
        </a>
      </p>
      <p className={classes.footerText}>Page views: {views}</p>
    </div>
  );
}
